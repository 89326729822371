export const returned_cheque_report_date_by = [
  { id: 1, name: "Issue date" },
  { id: 2, name: "Due Date" },
  { id: 3, name: "Return Date" },
];

export const collection_cheque_report_date_by = [
  { id: 1, name: "Issue date" },
  { id: 2, name: "Due Date" },
  { id: 3, name: "collect Date" },
];


